<template>
  <div :class="'content-filters-' + $route.name.trim()">
    <!-- #region FILTRO BUSQUEDA GENERAL -->
    <div
      class="content-filter-text-field"
      :class="'content-filter-text-field-' + $route.name.trim()"
    >
      <v-text-field
        v-model="sSearch"
        :label="labelName"
        :placeholder="placeholderName"
        class="global-text-field"
        color="var(--primary-color-border-input)"
        background-color="var(--primary-color-menu)"
        persistent-placeholder
        outlined
        height="50px"
        clearable
      >
        <template v-slot:append>
          <v-icon color="#7C8088" size="17px"> mdi-magnify </v-icon>
        </template>
      </v-text-field>

      <filter-menu-global
        class="ml-2"
        v-if="bShowMenuFilter"
        v-show="isMobile"
        @setExistentMenu="setExistent"
        @setVariantMenu="setVariant"
        @setSortNameMenu="setSortName"
        @setSortDateMenu="setSortDate"
        @setCurrencyMenu="setCurrency"
        @setAccountBalanceMenu="setAccountBalance"
        @setAccountTypeMenu="setAccountType"
        @setStatusOCMenu="setStatusOC"
      />
    </div>
    <!-- #endregion FILTRO BUSQUEDA GENERAL -->

    <!--#region FILTRO DE INSTITUCIONES (CUENTAS FINANCIERAS) -->
    <div v-if="bAccount" class="content-filter-select-institutions">
      <v-select
        v-model="sInstitution"
        :items="itemInstitution"
        item-text="sName"
        item-value="sFinancialInstitutionId"
        class="global-select"
        color="var(--primary-color-border-input)"
        background-color="var(--primary-color-menu)"
        outlined
        clearable
      >
        <template slot="label">
          <span>Instituciones <span class="important-data"></span></span>
        </template>
      </v-select>
    </div>
    <!--#endregion FILTRO DE INSTITUCIONES (CUENTAS FINANCIERAS) -->

    <!-- #region FILTRO DE RANGO DE FECHAS (O.C.) -->
    <div v-if="false" class="content-filter-date-range" @click="menu = true">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-combobox
            v-model="aDateRange"
            multiple
            chips
            small-chips
            outlined
            clearable
            class="global-text-field global-text-field-date"
            label="Seleccionar rango de fechas"
            placeholder="Seleccionar rango de fechas..."
            color="var(--primary-color-border-input)"
            background-color="var(--primary-color-menu)"
            v-bind="attrs"
            v-on="on"
          >
            <template slot="append">
              <v-icon>mdi-calendar-blank</v-icon>
            </template>
          </v-combobox>
        </template>
        <v-date-picker
          v-model="aDateRange"
          :max="sMaxDate"
          range
          no-title
          locale="es"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="$refs.menu.save((aDateRange = []))"
          >
            Cancelar
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu.save(aDateRange), setDate(aDateRange)"
          >
            <span
              :style="
                this.aDateRange.length == 2
                  ? { color: 'var(--primary-color-text-yellow)' }
                  : { color: '' }
              "
              >Guardar</span
            >
          </v-btn>
        </v-date-picker>
      </v-menu>
    </div>
    <!-- #endregion FILTRO DE RANGO DE FECHAS (O.C.) -->

    <!-- #region FILTRO DE MODULO ASOCIADO(AUDITORÍA) -->
    <div
      v-if="bShowFilterAssociatedModule"
      class="content-filter-select-associate-module"
    >
      <v-select
        v-model="sAssociatedModule"
        :items="itemAssociatedModule"
        item-text="sModuleName"
        item-value="sModuleId"
        class="global-select"
        color="var(--primary-color-border-input)"
        background-color="var(--primary-color-menu)"
        outlined
        clearable
      >
        <template slot="label">
          <span>Módulo asociado <span class="important-data"></span></span>
        </template>
      </v-select>
    </div>
    <!-- #endregion FILTRO DE MODULO ASOCIADO(AUDITORÍA) -->

    <!--#region MENU DE FILTRO ABACO -->
    <div
      v-if="bShowMenuFilter"
      v-show="!isMobile"
      class="content-filter-add-raw-material"
    >
      <filter-menu-global
        @setExistentMenu="setExistent"
        @setVariantMenu="setVariant"
        @setSortNameMenu="setSortName"
        @setSortDateMenu="setSortDate"
        @setCurrencyMenu="setCurrency"
        @setAccountBalanceMenu="setAccountBalance"
        @setAccountTypeMenu="setAccountType"
        @setStatusOCMenu="setStatusOC"
        @setTypeOperationMenu="setTypeOperation"
      />
    </div>
    <!--#endregion MENU DE FILTRO ABACO -->
  </div>
</template>

<script>
export default {
  props: {
    labelName: String,
    placeholderName: String,
  },
  data() {
    return {
      aShowMenuFilter: [
        "RawMaterial",
        "Administrators",
        "Account",
        "OPGeneral",
        "OPPending",
        "OPPayable",
      ],
      aShowFilterAssociatedModule: ["Audit"],
      isMobile: false,
      sSearch: "",
      sInstitution: null,
      itemInstitution: [],
      sAssociatedModule: null,
      itemAssociatedModule: [],
      aDateRange: [
        new Date().toISOString().slice(0, 10),
        new Date().toISOString().slice(0, 10),
      ],
      menu: false,
      sMaxDate: new Date().toISOString().slice(0, 10),
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  beforeMount() {
    this.setFillFieldFilter();
    this.getInstitution();
    this.getAssociatedModule();
  },
  methods: {
    //#region RESIZE PAGE

    handleResize() {
      this.$nextTick((e) => {
        if (window.innerWidth > 599.99) {
          this.isMobile = false;
        } else {
          this.isMobile = true;
        }
      });
    },
    //#endregion RESIZE PAGE

    setFillFieldFilter() {
      if (this.sSearchGlobal) {
        this.sSearch = this.sSearchGlobal;
      }
    },
    getInstitution() {
      if (this.bAccount) {
        DB.get(`${URI}/api/sp/v1/financial-institutions`, {
          params: {},
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
        })
          .then((response) => {
            this.itemInstitution = response.data.results;
            this.itemInstitution.unshift({
              sName: "Seleccionar institución",
              sFinancialInstitutionId: null,
              disabled: true,
            });
          })
          .catch((error) => {
            this.bLoadingTable = false;
            this.Error(error.response.data);
          });
      }
    },
    getAssociatedModule() {
      if (this.bShowFilterAssociatedModule) {
        DB.get(`${URI}/api/sp/v1/permissions/modules`, {
          params: {},
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
        })
          .then((response) => {
            if (this.bAudit) {
              this.itemAssociatedModule = response.data.results.filter(
                function (obj) {
                  return (
                    obj.sModuleId !== "f0831b6b-3c6d-46c2-9327-97800e73bd20" &&
                    obj.sModuleId !== "9d99fb23-2d47-4ada-aeca-a6e3f3d236e5"
                  );
                }
              );
            } else {
              this.itemAssociatedModule = response.data.results;
            }
            this.itemAssociatedModule.unshift({
              sModuleName: "Seleccionar módulo",
              sModuleId: null,
              disabled: true,
            });
          })
          .catch((error) => {
            this.Error(error.response.data);
          });
      }
    },
    setExistent(val) {
      this.$emit("setExistent", val);
    },
    setVariant(val) {
      this.$emit("setVariant", val);
    },
    setSortName(val) {
      this.$emit("setSortName", val);
    },
    setSortDate(val) {
      this.$emit("setSortDate", val);
    },
    setCurrency(val) {
      this.$emit("setCurrency", val);
    },
    setAccountBalance(val) {
      this.$emit("setAccountBalance", val);
    },
    setAccountType(val) {
      this.$emit("setAccountType", val);
    },
    setStatusOC(val) {
      this.$emit("setStatusOC", val);
    },
    setTypeOperation(val) {
      this.$emit("setTypeOperation", val);
    },
    setDate(val) {
      if (val.length == 2) {
        let sStartDate = val[0];
        let sEndDate = val[1];
        this.$emit("setDateRange", {
          sStartDate: sStartDate,
          sEndDate: sEndDate,
        });
      }
    },
  },
  computed: {
    sNameRouteGlobal() {
      return this.$store.state.sNameRouteGlobal;
    },
    sSearchGlobal() {
      return this.$store.state.sSearchGlobal;
    },
    sTabPosition() {
      return this.$store.state.sTabPosition;
    },
    bShowMenuFilter() {
      return (
        this.aShowMenuFilter.filter((e) => e === this.$route.name).length > 0
      );
    },
    bAccount() {
      return this.$route.name == "Account";
    },
    bShowFilterAssociatedModule() {
      return (
        this.aShowFilterAssociatedModule.filter((e) => e === this.$route.name)
          .length > 0
      );
    },
    bAudit() {
      return this.$route.name === "Audit";
    },
  },
  watch: {
    // $route: {
    //   immediate: true,
    //   deep: true,
    //   handler(route) {
    //     if (
    //       route.name === "OPGeneral" ||
    //       route.name === "OPPending" ||
    //       route.name === "OPPayable"
    //     ) {
    //       if (this.sNameRouteGlobal !== route.name) {
    //         this.$store.commit("setSearchGlobal", "");
    //         this.$store.commit("setStatusGlobal", []);
    //         this.$store.commit("setTypeOperationGlobal", "");
    //         this.$store.commit("setRangeDateGlobal", []);
    //         this.$store.commit("setCurrentPageGlobal", 1);
    //         this.$store.commit("setItemsPerPageGlobal", 30);
    //       }
    //       this.$store.commit("setNameRouteGlobal", route.name);
    //     } else {
    //       this.$store.commit("setSearchGlobal", "");
    //       this.$store.commit("setStatusGlobal", []);
    //       this.$store.commit("setTypeOperationGlobal", "");
    //       this.$store.commit("setRangeDateGlobal", []);
    //       this.$store.commit("setCurrentPageGlobal", 1);
    //       this.$store.commit("setItemsPerPageGlobal", 30);
    //     }
    //   },
    // },
    sTabPosition() {
      this.sSearch = "";
      this.$emit("setSearch", "");
    },
    sSearch() {
      this.$store.commit("setSearchGlobal", this.sSearch);
      this.$emit("setSearch", this.sSearch);
    },
    sInstitution() {
      this.$emit("setInstitution", this.sInstitution);
    },
    sAssociatedModule() {
      this.$emit("setAssociatedModule", this.sAssociatedModule);
    },
    aDateRange() {
      if (this.aDateRange.length == 0) {
        let sStartDate = "";
        let sEndDate = "";
        this.$emit("setDateRange", {
          sStartDate: sStartDate,
          sEndDate: sEndDate,
        });
      }
    },
  },
};
</script>

<style scoped>
.content-search-raw-material {
  display: block;
  align-content: center;
  width: 100%;
}

.content-filters-Provider {
  display: flex;
  margin-top: 10px;
}

.content-filters-RawMaterial {
  display: flex;
  margin-top: 10px;
}

.content-filters-Administrators {
  display: flex;
  margin-top: 10px;
}

.content-filters-Account {
  display: flex;
  margin-top: 10px;
}

.content-filters-OPGeneral {
  display: flex;
  margin-top: 0px;
  padding: 10px 0px 0px 0px !important;
}

.content-filters-OPPending {
  display: flex;
  margin-top: 0px;
  padding: 10px 10px 0px 0px !important;
}

.content-filters-OPPayable {
  display: flex;
  margin-top: 0px;
  padding: 10px 10px 0px 10px !important;
}

.content-filters-Customer {
  display: flex;
  margin-top: 10px;
}

.content-filters-Audit {
  display: flex;
  margin-top: 10px;
}

.content-filter-text-field {
  min-width: 390px;
  margin-bottom: 10px;
}

.content-filter-text-field-OPGeneral {
  width: 380px !important;
  max-width: 380px !important;
  min-width: 380px !important;
  margin-bottom: 10px;
}

.content-filter-select-institutions {
  /* width: 60%; */
  margin-left: 15px;
  min-width: 390px;
  margin-bottom: 10px;
}

.content-filter-select-associate-module {
  /* width: 60%; */
  margin-left: 15px;
  min-width: 390px;
  margin-bottom: 10px;
}

.content-filter-add-raw-material {
  display: flex;
  justify-content: end;
  align-content: center;
  width: 100%;
}

.content-text-title {
  width: 100%;
  margin-bottom: 10px;
}

.text-title {
  color: var(--primary-color-text);
  font-family: "pop-SemiBold";
  font-size: 2rem;
  margin-bottom: 0px;
}

.title-filter {
  font-size: 14px;
  font-family: "pop-Bold";
}

.text-checkbox {
  font-size: 14px;
  font-family: "pop-Light";
}

.content-filter-date-range {
  width: 300px;
  max-width: 300px;
  min-width: 300px;
  margin-left: 15px;
}

.content-filter-menu {
  background-color: var(--primary-color-menu-filter) !important;
  width: auto;
  min-width: 245px;
  max-width: 550px;
  overflow: hidden;
  border-radius: 10px !important;
  border: var(--primary-color-border-menu-profile) solid 1px;
}

/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-search-raw-material {
    display: block;
  }

  .content-filter-add-raw-material {
    display: block;
  }

  .content-filter-raw-material {
    margin-bottom: 15px;
  }

  .content-filters {
    display: block;
  }

  .content-filters-Provider {
    display: block;
  }

  .content-filters-RawMaterial {
    display: block;
  }

  .content-filters-Administrators {
    display: block;
  }

  .content-filters-Account {
    display: block;
  }

  .content-filters-OPGeneral {
    display: block;
    margin-top: 0px;
    padding: 0px 0px 0px 0px !important;
  }

  .content-filters-OPPending {
    display: block;
    margin-top: 0px;
    padding: 0px 0px 0px 0px !important;
  }

  .content-filters-Audit {
    display: block;
    margin-top: 0px;
  }

  .content-filter-select-institutions {
    /* width: 60%; */
    margin-left: 0px;
    min-width: 100%;
    margin-bottom: 10px;
  }

  .content-filter-select-associate-module {
    /* width: 60%; */
    margin-left: 0px;
    min-width: 100%;
    margin-bottom: 10px;
  }

  .content-filter-text-field {
    min-width: 100%;
    display: flex;
  }

  .content-filter-text-field-OPGeneral {
    min-width: 100% !important;
    margin-bottom: 10px;
  }

  .content-filter-text-field-OPGeneral {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
    margin-bottom: 10px;
  }

  .content-filter-date-range {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    margin-left: 0px;
    margin-bottom: 10px;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .content-filters-Account {
    display: flex;
  }

  .content-filter-text-field {
    /* width: 60%; */
    min-width: 230px;
    margin-bottom: 10px;
  }

  .content-filter-select-institutions {
    /* width: 60%; */
    min-width: 230px;
    margin-bottom: 10px;
  }

  .content-filter-select-associate-module {
    /* width: 60%; */
    min-width: 230px;
    margin-bottom: 10px;
  }

  .content-filter-text-field-OPGeneral {
    min-width: 180px !important;
    margin-bottom: 10px;
  }

  .content-filter-date-range {
    width: 266px;
    max-width: 266px;
    /* min-width: 266px; */
    margin-left: 15px;
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }

  .content-filter-text-field {
    /* width: 60%; */
    min-width: 230px;
    margin-bottom: 10px;
  }

  .content-filter-select-institutions {
    /* width: 60%; */
    min-width: 230px;
    margin-bottom: 10px;
  }

  .content-filter-select-associate-module {
    /* width: 60%; */
    min-width: 230px;
    margin-bottom: 10px;
  }

  .content-filter-text-field-OPGeneral {
    /* width: 18% !important; */
    min-width: 180px !important;
    margin-bottom: 10px;
  }

  .content-filter-date-range {
    width: 270px !important;
    max-width: 270px !important;
    min-width: 270px !important;
    margin-left: 15px;
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>
